.rootName {
  display: flex;
  flex-direction: column;
  block-size: 100%;
  gap: 10px;
  > .rdg {
    flex: 1;
  }
}

.toolBar {
  text-align: end;
}

.filterContainer {
  line-height: 35px;
  padding: 0;
  > div {
    padding-block: 0;
    padding-inline: 8px;
    &:first-child {
      border-block-end: 1px solid var(--rdg-border-color);
    }
  }
}

.filterName {
  inline-size: 100%;
  padding: 4px;
  font-size: 14px;
}

.loadingRowsStyle {
  inline-size: 200px;
  padding-block: 8px;
  padding-inline: 16px;
  position: absolute;
  inset-block-end: 8px;
  inset-inline-end: 8px;
  color: white;
  line-height: 35px;
  background: rgb(0 0 0 / 0.6);
}
