.rootStyle {
  display: flex;
  flex-direction: column;
  block-size: 100%;
  gap: 10px;

  > .rdg {
    flex: 1;
  }
}

.toolBarStyle {
  //text-align: end;
}

.filterContainerStyle {
  line-height: 35px;
  padding: 0;
  overflow: visible;
  > div {
    padding-block: 0;
    padding-inline: 8px;

    &:first-child {
      border-block-end: 1px solid var(--rdg-border-color);
    }
  }
}

.filterGridContainerStyle {
  //line-height: 35px;
  padding: 0;
  > div {
    padding-block: 0;
    padding-inline: 8px;

    &:first-child {
      border-block-end: 1px solid var(--rdg-border-color);
    }
  }
}

.filterStyle {
  inline-size: 100%;
  padding: 4px;
  font-size: 14px;
}
