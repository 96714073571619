@at-root {
    :root,
    body {
        padding: 0;
        margin: 0;
        font-family: sans-serif;
    }
    :root {
        color-scheme: light;
        @media (prefers-color-scheme: light) {
            background-color: #fff;
            color: #111;
        }
        @media (prefers-color-scheme: light) {
            background-color: hsl(0deg 0% 10%);
            color: #fff;
        }
    }
    #root {
        display: grid;
        grid-template-columns: auto 1fr;
    }

    .expanded {
        line-height: 1em;
        overflow: auto;
    }
}

.mainClass {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    block-size: 100vh;
    padding: 8px;
    width: 100vw;
    height: 100vh;
}

.rdg-main {
    height: 100vh;
}

.dateRange {
    flex-direction: column;
}
